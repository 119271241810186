<template>

  <router-view/>
</template>

<style lang="scss">
body{
  font-size: 0.2rem;
}
html::-webkit-scrollbar {
display: none;
}

.one{
  color:#F1DA54;
}
.two{
  color:#F28949;
}
.three{
  color:#E9575E;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: YouSheBiaoTiHei;
  src: url("@/assets/font/优设标题黑.ttf") format("truetype");
  font-stretch: "normal";
  font-weight: 400;
}
@font-face {
  font-family: PangMenZhengDao-3;
  src: url("@/assets/font/庞门正道标题体2.0增强版.ttf") format("truetype");
  font-stretch: "normal";
  font-weight: 400;
}



*{
  margin: 0;
  padding:0 ;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
