import { createRouter, createWebHistory } from 'vue-router'

// import LoginPage from '../views/loginPage.vue'
const routes = [
  {
    path: '/',
    name: 'page',
   component: () => import(/* webpackChunkName: "about" */ '@/views/homePage.vue')
  },
  {
    path: '/login',
    name: 'login1',
   component: () => import(/* webpackChunkName: "about" */ '@/views/loginPage.vue')
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  console.log()
  //判断是否有权限返回登录界面
  if (to.fullPath!='/login'&&!localStorage.getItem('TOKEN')) {
      
          next("/login")
      
  } else {
      console.log("没有权限");
      next()
  }
})


export default router
